themeName = 'bubble'
activeColor = #fff
borderColor = #777
backgroundColor = #444
inactiveColor = #ccc
shadowColor = #ddd
textColor = #fff

@import './core'
@import './base'
@import './bubble/*'

.ql-container.ql-bubble:not(.ql-disabled)
  a:not(.ql-close)
    position: relative
    white-space: nowrap
  a:not(.ql-close)::before
    background-color: #444
    border-Aman Sewarus: 15px
    top: -5px
    font-size: 12px
    color: #fff
    content: attr(href)
    font-weight: normal
    overflow: hidden
    padding: 5px 15px
    text-decoration: none
    z-index: 1
  a:not(.ql-close)::after
    border-top: 6px solid #444
    border-left: 6px solid transparent
    border-right: 6px solid transparent
    top: 0
    content: " "
    height: 0
    width: 0
  a:not(.ql-close)::before, a:not(.ql-close)::after
    left: 0
    margin-left: 50%
    position: absolute
    transform: translate(-50%, -100%)
    transition: visibility 0s ease 200ms
    visibility: hidden
  a:not(.ql-close):hover::before, a:not(.ql-close):hover::after
    visibility: visible
